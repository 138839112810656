//security.js
import request from '@/utils/request'
import qs from 'qs'

// 查询员工个人资料
export function showstaff (id,state) {
  let data = {
  id,state
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/Showfws',
    method: 'post',
    data
  })
}
// 查找当前员工密码
export function showpassword (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/showoldpwd',
    method: 'post',
    data
  })
}
// 修改员工密码
export function changepassword (id,password) {
  let data = {
  id,password
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/modifystaffpwd',
    method: 'post',
    data
  })
};

// 向员工发送验证码
export function sendygcode (phone) {
  let data = {
  phone
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/fwsyanzhengma',
    method: 'post',
    data
  })
}

// 绑定手机号
export function bindtel (phone,phonecode,id) {
  let data = {
  phone,phonecode,id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/staffAddPhone',
    method: 'post',
    data
  })
}




